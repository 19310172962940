import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Double from '../components/double';
import Footer from '../components/footer';
import ImageText from '../components/image-text';
import SEO from '../components/seo';
import Single from '../components/single';
import TextImage from '../components/text-image';

function Project({ data }) {
  const { name, background, content, _rawContent } = data.sanityProject;

  const resolvedContent = content.map((el, index) => {
    switch (el._type) {
      case 'single':
        return <Single key={index} image={getImage(el.asset)} />;
      case 'double':
        return (
          <Double
            first={getImage(el.first.asset)}
            second={getImage(el.second.asset)}
            key={index}
          />
        );
      case 'image-text':
        return (
          <ImageText
            image={getImage(el.image.asset)}
            text={_rawContent[index].text}
            key={index}
          />
        );
      case 'text-image':
        return (
          <TextImage
            key={index}
            image={getImage(el.image.asset)}
            text={_rawContent[index].text}
          />
        );
      default:
        console.error('type does not exist');
        return false;
    }
  });

  return (
    <div className="text-sm text-gray-800 font-serif">
      <SEO
        title={name}
        meta={[
          {
            name: `robots`,
            content: `noindex`,
          },
        ]}
      />
      <div className="h-screen overflow-hidden relative mb-5 md:mb-10">
        <div className="absolute top-0 left-0 h-full w-full">
          <GatsbyImage
            image={getImage(background.asset)}
            alt=""
            className="h-full w-full"
          />
        </div>
        <h1 className="z-10 tracking-tight relative text-4xl leading-none p-5 md:p-10 md:text-5xl">
          {name}
        </h1>
      </div>
      <div className="mb-20">{resolvedContent}</div>
      <Footer />
    </div>
  );
}

export default Project;

export const postQuery = graphql`
  query PageQuery($id: String!) {
    sanityProject(id: { eq: $id }) {
      background {
        asset {
          gatsbyImageData(width: 1440)
        }
      }
      name
      _rawContent(resolveReferences: { maxDepth: 10 })
      content {
        ... on SanityDouble {
          _type
          first {
            asset {
              gatsbyImageData(width: 1440, height: 1920)
            }
          }
          second {
            asset {
              gatsbyImageData(width: 1440, height: 1920)
            }
          }
        }
        ... on SanityImageText {
          _type
          image {
            asset {
              gatsbyImageData(width: 1440)
            }
          }
        }
        ... on SanitySingle {
          _type
          asset {
            gatsbyImageData(width: 1440)
          }
        }
        ... on SanityTextImage {
          _type
          image {
            asset {
              gatsbyImageData(width: 1440)
            }
          }
        }
      }
    }
  }
`;
