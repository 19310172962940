import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const Double = ({ first, second }) => (
  <div className="px-5 md:px-6 mb-10 md:pb-56">
    <div className="md:w-5/6 md:flex mx-auto">
      <div className="md:w-1/2">
        <GatsbyImage image={first} alt="" className="mb-5" />
      </div>
      <div className="md:w-1/2 md:mt-auto">
        <div className="md:w-7/10 md:ml-auto md:-mb-56">
          <GatsbyImage image={second} alt="" className="mb-5" />
        </div>
      </div>
    </div>
  </div>
);

export default Double;
