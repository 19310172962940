import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const Single = ({ image }) => (
  <div className="px-5 md:px-6 ">
    <div className="-mx-5 md:mx-0 mb-10 relative overflow-hidden">
      <div className="w-full md:h-screen full-screen" />
      <div className="absolute top-0 left-0 w-full h-full z-10">
        <GatsbyImage image={image} alt="" className="w-full h-full" />
      </div>
    </div>
  </div>
);

export default Single;
